<script setup lang="ts">
import type { FormSubmitEvent, TabItem } from '#ui/types'
import { z } from 'zod'

type LoginState =
  | {
      type: 'waiting'
    }
  | {
      type: 'loading'
    }
  | {
      type: 'success'
    }
  | {
      type: 'error'
      error: string
    }

definePageMeta({
  layout: 'auth'
})
useHead({
  title: 'Login'
})

const tabItems: TabItem[] = [
  {
    slot: 'email-password',
    label: 'Password'
  },
  {
    slot: 'magic-link',
    label: 'Magic Link'
  }
]

const { sendMagicLink, signInWithPassword } = useAuth()

const magicLinkFormSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, 'Your email address is required')
    .email('Invalid email address')
})

const passwordFormSchema = z.object({
  email: z
    .string()
    .trim()
    .min(1, 'Your email address is required')
    .email('Invalid email address'),
  password: z.string().trim().min(1, 'Your password is required')
})

type MagicLinkFormSchema = z.output<typeof magicLinkFormSchema>
type PasswordFormSchema = z.output<typeof passwordFormSchema>

const magicLinkState = reactive({
  email: ''
})
const passwordState = reactive({
  email: '',
  password: ''
})
const magicLinkLoginState = ref<LoginState>({ type: 'waiting' })
const passwordLoginState = ref<LoginState>({ type: 'waiting' })
const loading = computed(() => magicLinkLoginState.value.type === 'loading')

const onSubmitEmailPass = async (
  event: FormSubmitEvent<PasswordFormSchema>
) => {
  passwordLoginState.value = { type: 'loading' }
  const { checkTourCompletion } = useTours()

  try {
    const { error } = await signInWithPassword(
      event.data.email,
      event.data.password
    )

    if (error) throw error

    passwordLoginState.value = { type: 'success' }

    await checkTourCompletion()

    await navigateTo('/')
  } catch (error) {
    passwordLoginState.value = {
      type: 'error',
      error: getAuthErrorMessage('password', error)
    }
  }
}

const onErrorEmailPass = async () => {
  passwordLoginState.value = {
    type: 'error',
    error: 'Please check the form for issues and try again.'
  }
}

const onSubmitMagicLink = async (
  event: FormSubmitEvent<MagicLinkFormSchema>
) => {
  magicLinkLoginState.value = { type: 'loading' }

  try {
    const { error } = await sendMagicLink(event.data.email)

    if (error) throw error

    magicLinkLoginState.value = { type: 'success' }
  } catch (error) {
    magicLinkLoginState.value = {
      type: 'error',
      error: getAuthErrorMessage('magic-link', error)
    }
  }
}

const onErrorMagicLink = async () => {
  magicLinkLoginState.value = {
    type: 'error',
    error: 'Please check the form for issues and try again.'
  }
}
</script>

<template>
  <UCard>
    <template #header>
      <div class="flex items-center justify-center gap-8">
        <CommonLogo />
      </div>
    </template>
    <UTabs :items="tabItems" class="w-full">
      <template #email-password>
        <UForm
          :schema="passwordFormSchema"
          :state="passwordState"
          class="space-y-4"
          @submit="onSubmitEmailPass"
          @error="onErrorEmailPass"
        >
          <UAlert
            color="orange"
            variant="outline"
            title="Don't have your password? Click on Magic Link!"
            description="From there you can use your email address to log in."
          />
          <UAlert
            v-if="passwordLoginState.type === 'error'"
            icon="i-heroicons-exclamation-circle"
            color="red"
            variant="outline"
            title="Error"
            :description="passwordLoginState.error"
          />
          <UAlert
            v-if="passwordLoginState.type === 'success'"
            icon="i-heroicons-check"
            color="green"
            variant="outline"
            title="Success"
            description="Hang tight, we are redirecting you to your dashboard."
          />
          <p>Enter your email and password to log into Retail Navigator</p>
          <UFormGroup label="Email" name="email">
            <UInput
              v-model="passwordState.email"
              type="email"
              autocomplete="email"
            />
          </UFormGroup>
          <UFormGroup label="Password" name="password">
            <UInput
              v-model="passwordState.password"
              type="password"
              autocomplete="current-password"
            />
          </UFormGroup>
          <UButton type="submit" :disabled="loading" :loading="loading">
            Login
          </UButton>
        </UForm>
      </template>
      <template #magic-link>
        <UForm
          :schema="magicLinkFormSchema"
          :state="magicLinkState"
          class="space-y-4"
          @submit="onSubmitMagicLink"
          @error="onErrorMagicLink"
        >
          <UAlert
            v-if="magicLinkLoginState.type === 'error'"
            icon="i-heroicons-exclamation-circle"
            color="red"
            variant="outline"
            title="Error"
            :description="magicLinkLoginState.error"
          />
          <UAlert
            v-if="magicLinkLoginState.type === 'success'"
            icon="i-heroicons-check-circle"
            color="green"
            variant="outline"
            title="Success"
            description="Check your email for a login link."
          />
          <p>
            Login without a password by entering your email address. We will
            send you a magic link to log in with.
          </p>
          <UFormGroup label="Email" name="email">
            <UInput
              v-model="magicLinkState.email"
              type="email"
              autocomplete="email"
            />
          </UFormGroup>
          <UButton type="submit" :disabled="loading" :loading="loading">
            Send Link
          </UButton>
        </UForm>
      </template>
    </UTabs>
  </UCard>
</template>
